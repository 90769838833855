import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { RadioButton, RadioButtonChangeParams } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { ITypeCDA, TUser } from "../../..";
import { DebtsService } from '../../fe-api/services/DebtsService';
import { CategoryUtil } from "../util/CategoryUtil";
import { Constants } from "../util/Constants";
import { formatCurrency } from "../util/Currency";
import pdf from '../util/ExportFile';
import DebtsTable from "./DebtsTable";
import { useToast } from "./hooks/useToast";

const DebtsSearch = ({ usuarioLogado }: { usuarioLogado: TUser }) => {

    // Hooks useState
    const [documento, setDocumento] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [debts, setDebts] = useState<ITypeCDA[]>([]);
    const [radioSelected, setRadioSelected] = useState(CategoryUtil.value(0));
    const [visible, setVisible] = useState(false);
    const [rows, setRows] = useState(20);

    // Hooks useRef
    const totalRecords = useRef(0);
    const consultado = useRef<string>("");

    // Hook useToast
    const [toastRef, showToast] = useToast(null);

    // Instance DebtService
    const debtsService = new DebtsService();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!radioSelected.validate(documento)) {
            showErrorMessage('Documento Inválido', `O ${radioSelected.label} informado é inválido.`);
            setVisible(false);
            return;
        }

        try {
            setLoading(true);

            if (!visible) {
                setVisible(true)
            }

            await fetchDataByPage(documento, radioSelected.key, 1);
        } catch (error) {
            console.error(error);
        }
    }

    const handlePagination = async (offset: number, rows: number) => {
        try {
            setLoading(true);
            await fetchDataByPage(consultado.current, radioSelected.key, (offset / rows) + 1);
        } catch (error) {
            console.error(error);
        }

        setRows(rows);
    }

    const fetchDataByPage = async (registration: string, type: string, page: number) => {
            try {
                const cdas = await debtsService.getCdas(registration, type, page);

                if (consultado.current === registration) {
                    if (cdas.success === 1) {
                        setDebts(cdas.result);
                    }
                } else {
                    totalRecords.current = cdas.goal?.totalRecords || 0;
                    setDebts(cdas.success === 1 ? cdas.result : []);
                    consultado.current = registration;
                }
            } catch(error) {
                setVisible(false);
                showErrorMessage('Falha de Comunicação',
                    `Não foi possível obter os dados do ${radioSelected.label}. Por favor, tente novamente mais tarde.`);
            } finally {
                if (documento !== "") {
                    setDocumento("");
                }
                setLoading(false);
            }
    }

    const fetchData = async (registration: string, type: string) => {
        const data: ITypeCDA[] = [];

        try {
            const cdas = await debtsService.getCdas(registration, type);

            if (cdas.success === 1) {
                [...cdas.result].forEach(d => {
                    data.push({
                        numeroCDA: d.numeroCDA,
                        documento: d.contribuinte.documento.numero,
                        contribuinte: d.contribuinte.nome,
                        dataInscricaoCDA: d.dataInscricaoCDA,
                        situacaoAtualProcesso: d.situacaoAtualProcesso,
                        tipoProcesso: d.tipoProcesso,
                        valorTotal: formatCurrency(d.valorTotal)
                    });
                });

                return data;
            } else {
                if (cdas.result.status === 504) {
                    showErrorMessage('Erro na exportação', `Não foi possível exportar os dados do ${radioSelected.label} por exceder o número de registros.`);
                }
            }
        } catch (error) {
            showErrorMessage('Falha de Comunicação',
                `Não foi possível exportar os dados do ${radioSelected.label} para o arquivo. Por favor, tente novamente mais tarde.`);
        }
    }

    const handleRadioChange = (event: RadioButtonChangeParams) => {
        const value = event.value;
        if (radioSelected.key !== value.key) {
            setRadioSelected(value);
            if (visible) setVisible(!visible);
            consultado.current = "";
            setDocumento("");
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDocumento(radioSelected.applyMask(event.target.value));
    }

    const exportPdf = async () => {
        setExportLoading(true);

        showInfoMessage('Exportando para arquivo', `Por favor, aguarde!`);

        const filename: string = 'documento.pdf';

        const headers = [
            {key: 'numeroCDA', label: 'Número CDA'},
            {key: 'documento', label: 'Documento'},
            {key: 'contribuinte', label: 'Contribuinte'},
            {key: 'dataInscricaoCDA', label: 'Data Inscrição'},
            {key: 'situacaoAtualProcesso', label: 'Situação Atual'},
            {key: 'tipoProcesso', label: 'Tipo'},
            {key: 'valorTotal', label: 'Valor Total'},
        ];

        try {
            const data = await fetchData(consultado.current, radioSelected.key)

            if (data) {
                pdf({title: Constants.TITLE_REPORT, data, headers, filename, other: usuarioLogado});
                showSuccessMessage('Exportado para arquivo', `Arquivo exportado com sucesso.`);
            }

        } catch (error) {
            console.error(error);
            showErrorMessage('Erro na exportação para arquivo', `Não foi possível exportar os dados do ${radioSelected.label} para o arquivo. Por favor, tente novamente mais tarde.`);
        } finally {
            setExportLoading(false)
        }
    }

    const showSuccessMessage = (title: string, message: string) => {
        showToast('success', title, message, 3000);
    }

    const showInfoMessage = (title: string, message: string) => {
        showToast('info', title, message, 3000);
    }

    const showErrorMessage = (title: string, message: string) => {
        showToast('error', title, message, 3000);
    }

    useEffect(() => {
        setButtonDisabled(radioSelected.length !== documento.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documento]);

    return (
        <>
            <Toast ref={toastRef} />
            <div className="App-body">
                <Panel header="Consultar Dívida Ativa" className="mb" style={{ padding: "10px 5px 50px" }}>
                    <p style={{ marginBottom: "20px" }}>No campo abaixo, você poderá consultar todos os débitos inscritos
                        em dívida ativa estadual.</p>
                    <Fieldset legend="Escolha uma das opções de documento abaixo">
                        <div className="formgrid grid">
                            <form onSubmit={handleSubmit}>
                                <div className="formgroup-inline">
                                    {CategoryUtil.values().map(category =>
                                        <div key={category.key} className="field-radiobutton">
                                            <RadioButton
                                                id={category.key}
                                                name="rdoDocumento"
                                                value={category}
                                                onChange={handleRadioChange}
                                                checked={category.key === radioSelected.key}
                                            />
                                            <label htmlFor={category.key}>{category.label}</label>
                                        </div>)}
                                </div>
                                <div className="formgroup-inline">
                                    <div className="field">
                                        <input
                                            type="text"
                                            id="txtDocumento"
                                            name="txtdocumento"
                                            value={documento}
                                            maxLength={radioSelected.length}
                                            placeholder={`Digite o número do ${radioSelected.label}`}
                                            onChange={handleChange}
                                            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                        />
                                    </div>

                                    <Button
                                        label="Consultar"
                                        icon="pi pi-search"
                                        className="p-button-warning"
                                        disabled={buttonDisabled}
                                    />
                                </div>
                            </form>
                        </div>
                    </Fieldset>&nbsp;

                    {visible &&
                        <DebtsTable
                            debts={debts}
                            rows={rows}
                            loading={loading}
                            exportLoading={exportLoading}
                            totalRecords={totalRecords.current}
                            export={exportPdf}
                            emptyMessage={`Nenhum registro encontrado para o ${radioSelected.label} ${radioSelected.applyMask(consultado.current)}`}
                            onPagination={handlePagination}
                        />}
                </Panel>
            </div>
        </>
    );
}

export default DebtsSearch;