const tokenSymbol: symbol = Symbol.for('accessToken');

type TokenObject = {
  [key: symbol]: string | undefined;
};

const internalToken = new Proxy<TokenObject>({ [tokenSymbol]: undefined }, {
    get(target: TokenObject, prop: symbol): string | undefined {
        return Reflect.get(target, prop);
    },
    set(target: TokenObject, prop: symbol, value: string | undefined): boolean {
        return Reflect.set(target, prop, value);
    }
});

const TokenUtils = {
    getToken(): string {
        return internalToken[tokenSymbol] ?? '';
    },
    setToken(value: string) {
        internalToken[tokenSymbol] = value.trim() ? value : undefined;
    }
}

export { TokenUtils };
