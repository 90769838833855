export class Constants {
    // FOOTER
    static readonly APP_NAME: string = 'Transparência PGE';
    static readonly DEVELOPED_BY: string = 'Desenvolvido pela STI';
    static readonly  STATE_BODY: string = 'PGE/MT';

    // PDF
    static readonly TITLE_REPORT: string =  'Relatório dos Dados Consultados';
    static readonly DEBIT_REFERENCE: string = 'Referência da Dívida';

    // USEFUL LINKS
    static readonly HOME_PAGE: string = 'http://www.pge.mt.gov.br';
    static readonly COMMON_QUESTIONS: string = Constants.HOME_PAGE + '/perguntas-frequentes';
    static readonly OUVIDORIA: string = Constants.HOME_PAGE + '/ouvidoria';

    // EXTERNAL LINKS
    static readonly LGPD: string = 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm';
    static readonly MARCO_CIVIL_INTERNET: string = 'https://www.planalto.gov.br/ccivil_03/_ato2011-2014/2014/lei/l12965.htm';
    static readonly CODIGO_TRIBUTARIO_NACIONAL: string = 'https://www.planalto.gov.br/ccivil_03/leis/L5172.htm#art198';
}


