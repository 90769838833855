import { removeMask } from "../../app/util/Mask";
import { Endpoints } from "../Endpoints";
import {TokenUtils} from "../../app/util/TokenUtils";

export class DebtsService {

    async getCdas(documento: string, tipo: string, page?: number) {
            let uri = `${Endpoints.CDAS}?documento=${removeMask(documento)}&tipoDocumento=${tipo}`;
            uri = page ? uri.concat(`&page=${page}`) : uri.concat('&export=true');

            const response = await fetch(uri, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${TokenUtils.getToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let data: any;
            switch (response.status) {
                case 200:
                    data = await response.json();
                    break;
                case 400:
                case 204:
                    data = {
                        dados: []
                    };
                    break;
                default:
                    throw response;
            }

            return {
                goal: {
                    pageSize: data.meta?.tamanhoPagina || 0,
                    totalRecords: data.meta?.totalRegistros || 0
                },
                success: data.sucesso || 0,
                result: data.dados || []
            }
    }
}