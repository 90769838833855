import jsPDF, { jsPDFOptions } from "jspdf";
import {Constants} from "./Constants";

const page = {
    unit: 'pt',
    format: 'a4',
    layout: {
        orientation: 'landscape',
        border: {
            header: 40,
            footer: 20
        }
    },
    dimensions: {
        height: 595.28,
        width: 841.89
    }
};

const addWaterMark = (doc: jsPDF, text: string) => {
    const xCenter = doc.internal.pageSize.width / 2;
    const yCenter = doc.internal.pageSize.height / 2;
    const offset = 230;

    doc.setTextColor(150).setFontSize(70);
    doc.text(text, xCenter - offset, yCenter + offset, { angle: 45, charSpace: 10 });
    doc.setTextColor(0).setFontSize(8);
}

const addHeader = (doc: any, title: string, pathImage: string = '') => {
    const addImageToHeader = (url: string, doc: jsPDF, x: number, y: number, w: number, h: number) => {
        doc.addImage((src => {
            const image = new Image();
            image.src = src;
            return image;
        })(url), 'png', x, y, w, h);

        return doc;
    }

    const totalPages = doc.internal.getNumberOfPages();

    for (let i = 0; i < totalPages; i++) {
        doc.setPage(i + 1);

        doc.setFillColor(0, 4, 68);
        doc.rect(0, 0, doc.internal.pageSize.width, page.layout.border.header, 'F');

        if (pathImage) {
            doc = addImageToHeader(pathImage, doc, 10, 5, 100, 30);
        }

        doc.setTextColor(255);
        doc.setFontSize(14);
        doc.text(title.toUpperCase(), (doc.internal.pageSize.width - doc.getTextWidth(title)) / 2, 25);

        doc.setFontSize(9);
        const dateTime = new Date().toLocaleString('pt-BR', { month: 'numeric', year: 'numeric' });
        const referencia = `${Constants.DEBIT_REFERENCE}: ${dateTime}`;
        doc.text(referencia, (doc.internal.pageSize.width - doc.getTextWidth(referencia) - 10), 25);
    }

    doc.setFillColor(0);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);

    return doc;
}

const addFooter = (doc: any, centerText: string) => {
    const totalPages = doc.internal.getNumberOfPages();

    for (let i = 0; i < totalPages; i++) {
        doc.setPage(i + 1);

        doc.setFillColor(0, 4, 68);
        doc.rect(0, doc.internal.pageSize.height - page.layout.border.footer, doc.internal.pageSize.width, page.layout.border.footer, 'F');

        doc.setTextColor(255);

        const currentPage = doc.getCurrentPageInfo().pageNumber;
        const yPos = doc.internal.pageSize.height - page.layout.border.footer / 2;
        const dateTime = new Date().toLocaleString('pt-BR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: "numeric",
            minute: "numeric"
        });

        doc.text(`Pagina ${currentPage} de ${totalPages}`, 10, yPos);
        doc.text(centerText, (doc.internal.pageSize.width - doc.getTextWidth(centerText)) / 2, yPos);
        doc.text(dateTime, (doc.internal.pageSize.width - doc.getTextWidth(dateTime) - 10), yPos);
    }

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);

    return doc;
}

const addHeadersTable = (doc: jsPDF, headers: any, liveArea: any, padding: number) => {
    const xPositions: any[] = [];

    // HEADERS
    headers.forEach((heading: any, index: number) => {
        // Se você decidir adicionar xPos aos mapeamentos de cabeçalho, isso será acionado
        if (heading.hasOwnProperty('xPos')) {
            doc.text(String(heading.label), heading.xPos, liveArea.margins.top);
            xPositions.push(heading.xPos);
        } else {
            // obter a posição x para o título
            const xPositionForCurrentHeader = liveArea.margins.left + index * (liveArea.width / headers.length);

            // precisa posicionar seu texto dentro em nossa liveArea 
            const yPositionForHeaders = liveArea.margins.top;
            doc.text(String(heading.label), index !== 0 ? xPositionForCurrentHeader : (xPositionForCurrentHeader + padding), yPositionForHeaders);

            // Empurre a posição x do cabeçalho atual para o array xPositions
            xPositions.push(index !== 0 ? xPositionForCurrentHeader : (xPositionForCurrentHeader + padding));
        }
    });

    doc.line(liveArea.margins.left, liveArea.margins.top + 3.5, liveArea.width - liveArea.margins.right, liveArea.margins.top + 3.5);

    return xPositions;
}

const pdf = ({ title, data, headers, filename, other } : { title: string, data: any, headers: any, filename: string, other: any }) => {

    let doc = new jsPDF({
        orientation: page.layout.orientation,
        unit: page.unit,
        format: page.format
    } as jsPDFOptions);

    const liveArea = {
        margins: {
            top: page.layout.border.header + 20,
            left: 20,
            right: 20,
            bottom: page.layout.border.footer + 10
        },
        width: page.dimensions.width,
        height: page.dimensions.height - page.layout.border.header - page.layout.border.footer
    };

    addWaterMark(doc, other.cpf);

    const padding = 2;

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);

    const xPositions = addHeadersTable(doc, headers, liveArea, padding);

    const baseYPosForRows = liveArea.margins.top + 3.5 + padding + 10;
    let nextYPos = baseYPosForRows;

    // ROWS
    data.forEach((row: any, rIndex: number, array: any) => {
        // Aqui vamos coletar todas as alturas máximas potenciais das colunas (abaixo)
        // Antes de determinarmos a nextYPosition, temos que pegar o valor mais alto
        // e adiciona isso à altura anterior.
        const rowHeights: number[] = [];

        /*
        *
        * Estilos de linha vão aqui (linhas, imagens, formas)
        *
        * */

        // COLUMNS
        headers.forEach((column: any, colIndex: number) => {

            // O uso do método .splitTextToSize receberá uma string e um parâmetro de largura.
            // Retornará um array de strings.
            const longText = doc.splitTextToSize(String(row[column.key]), xPositions[colIndex] - xPositions[(colIndex !== 0 && colIndex - 1) as number]);

            // Para obter a altura da linha, usaremos o método .getLineHeight
            // Este método retorna uma altura de linha com base no tamanho do
            // texto definido para o documento. Multiplicado pelo comprimento
            // do array, seu valor deve ser no mínimo uma linha padrão de texto
            // ou no máximo a quantidade de linhas de texto pela altura da linha
            const rowHeight = longText.length * doc.getLineHeight();

            // Precisamos enviar esse valor de altura para o array de alturas para
            // a linha (acima)
            rowHeights.push(rowHeight);

            /*
            *
            *  Estilos de coluna vão aqui (linhas, imagens, formas)
            *
            * */

            doc.text(longText, xPositions[colIndex], nextYPos);
        })

        // Aqui está a expressão do acumulador para informar o ponto inicial da próxima linha
        nextYPos = nextYPos + padding + Math.max(...rowHeights, 30);

        // Ao gerar dados em loop, pode ser necessário adicionar páginas manualmente.
        // O bom é que definimos nossos limites em liveArea e podemos adicionar uma
        // nova página quando nosso yPosition os exceder. Precisamos tomar alguns
        // cuidados para redefinir a yPosition porque, se você não o fizer: a yPosition
        // persistirá na próxima página e provavelmente desaparecerá de vista à medida
        // que sua yPosition cresce.
        if (rIndex !== array.length - 1 && nextYPos > liveArea.height) {
            doc.addPage();
            addWaterMark(doc, other.cpf);
            addHeadersTable(doc, headers, liveArea, padding);
            nextYPos = baseYPosForRows;
        }
    });

    doc = addHeader(doc, title, require("../../assets/img/logo.png"));
    doc = addFooter(doc, `Emitido por ${other.name}, CPF: ${other.cpf}`);

    doc.save(filename);
}

export default pdf;