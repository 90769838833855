import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { rowsPerPage } from '../util/Configuration';
import { formatCurrency } from '../util/Currency';
import { IDebtsTableProperty } from '../../..';

const DebtsTable = (props: IDebtsTableProperty): JSX.Element => {

    const [first, setFirst] = useState<number>(0);
    const [rows, setRows] = useState<number>(props.rows);

    const priceBodyTemplate = (rowData: any): string => {
        return formatCurrency(rowData?.valorTotal);
    }

    const onPageChange = (event: PaginatorPageState): void => {
        setFirst(event.first);
        setRows(event.rows);
        props.onPagination(event.first, event.rows);
    }

    const header: JSX.Element = (
        <>
            {props.totalRecords > 0 &&
                <div className="flex align-items-center justify-content-end gap-2">
                    <div>
                        Exportar para arquivo
                    </div>
                    <div>
                        <Button type="button" icon="pi pi-file-pdf" onClick={props.export} loading={props.exportLoading} data-pr-tooltip="PDF" />
                    </div>
                </div>}
        </>
    );

    return (
        <div style={{ padding: '10px 5px 20px' }}>
            <div className="card">
                <DataTable
                    header={header}
                    value={props.debts}
                    responsiveLayout="scroll"
                    loading={props.loading}
                    emptyMessage={props.emptyMessage}>
                    <Column field="numeroCDA" header="Número CDA" sortable />
                    <Column field="contribuinte.documento.numero" header="CPF/CNPJ" sortable />
                    <Column field="contribuinte.nome" header="Contribuinte" sortable />
                    <Column field="dataInscricaoCDA" header="Data Inscrição CDA" sortable />
                    <Column field="situacaoAtualProcesso" header="Situação Atual Processo" sortable />
                    <Column field="tipoProcesso" header="Tipo de Processo" sortable />
                    <Column field="valorTotal" header="Valor Total" body={priceBodyTemplate} sortable />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={props.totalRecords} rowsPerPageOptions={rowsPerPage} onPageChange={onPageChange}></Paginator>
            </div>
        </div>
    );
}

export default DebtsTable;