import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

const ShowDialog = (props: any) => {
    const [visibleState, setVisibleState] = useState(false);

    const handleHide = (): void => {
        setVisibleState(false);
        props.onAction && props.onAction();
    }

    const handleShow = () => {
        setVisibleState(true);
    }

    const footer: JSX.Element = (
        <div>
            <Button label="Ok" icon="pi pi-check" onClick={handleHide} autoFocus />
        </div>
    );

    useEffect(() => {
        if (props.visible) {
            handleShow();
        }
    }, [props.visible]);

    return (
        <Dialog
            header={props.header}
            footer={footer}
            visible={visibleState}
            onHide={handleHide}
            style={{ width: '40vw' }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}>
            <p className="m-0 justify-content-center">{props.message}</p>
        </Dialog>
    );
}

export default ShowDialog;