import { useEffect, useState } from "react";
import ShowDialog from "./lib/Dialog";

let countdownInterval: NodeJS.Timer;
let timeout: NodeJS.Timeout;

const SessionTimeout = ({timeInMinutes, logout}: { timeInMinutes: number; logout: () => void; }) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState<boolean>(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState<number>(0);

    const clearSessionTimeout = (): void => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = (): void => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async (isTimedOut: boolean = false): Promise<void> => {
        try {
            setTimeoutModalOpen(isTimedOut);
            clearSessionInterval();
            clearSessionTimeout();
            logout();
        } catch (err: unknown) {
            console.error(err);
        }
    };

    const onActive = (): void => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };

    const onIdle = (): void => {
        const delay = 1000 * 60 * timeInMinutes;
        if (!timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = 10;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };

    useEffect((): void => {
        onActive();
        onIdle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ShowDialog
            header="Tempo de Sessão Expirando."
            visible={timeoutModalOpen}
            message={`Sua sessão terminará em ${timeoutCountdown} segundos e em seguida redirecionado a tela de login. Caso queria continuar, realize um novo acesso.`}
            onAction={() => handleLogout(false)}
        />
    );
}

export default SessionTimeout;