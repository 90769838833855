import React, { useState } from 'react';
import { TUser } from '../../..';
import DebtsSearch from './DebtsSearch';
import Footer from './Footer';
import Navbar from './Navbar';
import Notification from './Notification';
import { RedirectLogin } from './RedirectLogin';
import SessionTimeout from './SessionTimeout';

const LogInOut = (props: { uriLogin: string; uriLogout: string; usuarioLogado: TUser; }) => {

    const [show, setShow] = useState<boolean>(false);

    const timeout: number = parseInt(process.env.REACT_APP_TIMEOUT_IN_MINUTES ?? '10') ;

    const handleHide = (): void => {
        setShow(true);
    }

    const handleLogout = async () => {
        window.location.replace(props.uriLogout);
    }

    const handleMain = () => {
        const partJSXElement = show ?
            (
                <>
                    <Navbar
                        usuarioLogado={props.usuarioLogado}
                        onLogout={handleLogout}
                    />
                    <DebtsSearch
                        usuarioLogado={props.usuarioLogado}
                    />
                </>
            ) : (
                <Notification onHide={handleHide} />
            );

        return (
            <>
                <SessionTimeout timeInMinutes={timeout} logout={handleLogout} />
                {partJSXElement}
                <Footer />
            </>
        );
    }

    return (
        <>
            {props.usuarioLogado?.cpf && handleMain()}
            {!props.usuarioLogado?.cpf && <RedirectLogin uri={props.uriLogin} />}
        </>
    );
}

export default LogInOut;