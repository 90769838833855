import {cnpjBaseMask, cnpjMask, cpfMask} from "./Mask";
import {validateCNPJ, validateCNPJBase, validateCPF} from "./Validity";

export interface ICategory {
    key: string;
    label: string;
    length: number;
    mask: string;
    applyMask: (value: string) => string;
    validate: (value: string) => boolean;
}

export class CategoryUtil {
    private static readonly categories: ICategory[] = [
        {
            key: 'CPF',
            label: 'CPF',
            length: 14,
            mask: "999.999.999-99",
            applyMask: cpfMask,
            validate: validateCPF
        },
        {
            key: 'CNPJ',
            label: 'CNPJ',
            length: 18,
            mask: "99.999.999/9999-99",
            applyMask: cnpjMask,
            validate: validateCNPJ
        },
        {
            key: 'RADICAL_CNPJ',
            label: 'CNPJ Base',
            length: 10,
            mask: "99.999.999",
            applyMask: cnpjBaseMask,
            validate: validateCNPJBase
        }
    ];

    static values() {
        return CategoryUtil.categories;
    }

    static value(index: number = 0): ICategory {
        return CategoryUtil.categories[index];
    }
}