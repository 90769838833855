import React, {useState} from "react";
import {Button} from 'primereact/button';
import {Checkbox, CheckboxChangeParams} from 'primereact/checkbox';
import Hyperlink from "./lib/Hyperlink";
import {Constants} from "../util/Constants";

const Notification = ({onHide}: { onHide: () => void }) => {

    const [checked, setChecked] = useState<boolean>(false);

    const handleChange = (event: CheckboxChangeParams): void => {
        event.preventDefault();
        setChecked(event.checked);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        onHide && onHide();
    }

    const renderBody = () => {
        return (
            <div>
                <p>
                    O presente <b>Termo de Uso</b> foi elaborado em conformidade com a&nbsp;
                    <Hyperlink
                        label={"Lei Federal nº 12.965, de 23 de abril de 2014"}
                        href={Constants.MARCO_CIVIL_INTERNET}
                    /> (Marco Civil da Internet), e com a&nbsp;
                    <Hyperlink
                        label={"Lei Federal nº 13.709, de 14 de agosto de 2018"}
                        href={Constants.LGPD}
                    /> (Lei Geral de Proteção de Dados Pessoais).
                </p><br/>

                <p>
                    A PGE/MT disponibiliza a <b>consulta dos créditos inscritos em Dívida ativa estadual</b>, e se
                    compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados Pessoais (LGPD), e
                    respeitar os princípios nela dispostos.
                </p><br/>

                <p>
                    Salienta-se que os débitos inscritos em dívida ativa <b>não</b> estão cobertos por sigilo, conforme
                    disposto no art. 198, §3º, II, do&nbsp;
                    <Hyperlink
                        label={"Código Tributário Nacional"}
                        href={Constants.CODIGO_TRIBUTARIO_NACIONAL}
                    />.
                </p><br/>

                <p>
                    A consulta abrange os créditos inscritos em Dívida ativa estadual, indicando sua respectiva
                    situação, e seu resultado pode ser exportado para arquivo no formato PDF. Os parâmetros de entrada
                    para a realização das buscas são CPF, CNPJ ou CNPJ Base, a fim de facilitar pesquisa do usuário.
                </p><br/>

                <p>
                    Os números dos CPFs dos devedores pessoa física foram <b>parcialmente mascarados</b>, preservando-se este
                    dado pessoal nos termos da Lei Geral de Proteção de Dados (
                    <Hyperlink
                        label={"Lei Federal nº 13.709/2018"}
                        href={Constants.LGPD}
                    />).
                </p><br/>

                <p>
                    Considerando as tecnologias envolvidas no processo ou a existência de intercorrências, informamos
                    que poderá demorar até <b>5 dias úteis</b> para ocorrer a baixa do débito no sistema de
                    acompanhamento de
                    dívidas.
                </p><br/>

                <p>
                    Salienta-se que o valor do crédito considera o ano e mês vigente como data de atualização do mesmo.
                </p><br/>

                <p>
                    A pesquisa retorna os débitos que se encontram nas seguintes situações processuais (<b>inscrito</b>,
                    &nbsp;<b>pré-ajuizado</b>, <b>em pagamento</b>, <b>em garantia de juízo</b>, <b>suspenso</b> e&nbsp;
                    <b>protestado</b>).
                </p><br/>

                <p>
                    Caso seja encontrada alguma inconsistência, favor entrar em contato com a Procuradoria Geral de
                    Mato Grosso através da&nbsp;
                    <Hyperlink
                        label={"Ouvidoria"}
                        href={Constants.OUVIDORIA}
                    /> pelo link:&nbsp;
                    <Hyperlink
                        label={Constants.OUVIDORIA}
                        href={Constants.OUVIDORIA}
                    />.
                </p><br/>

                <p>
                    Os dados retornados nas consultas não substituem e nem prejudicam os efeitos das informações
                    constantes nas certidões de regularidade fiscal emitidas pela PGE/MT.
                </p><br/>

                <p>
                    Ao utilizar os serviços, você confirma que leu, compreendeu o Termo de Uso aplicável ao serviço
                    solicitado e concorda em ficar a ele vinculado.
                </p><br/>
            </div>
        );
    }

    return (
        <div style={{margin: '20px', width: '95vw', textAlign: "justify", paddingBottom: '50px'}}>
            <div>
                <h1 style={{paddingBottom: '20px'}}>
                    <strong>TERMO DE USO DO PORTAL DA TRANSPARÊNCIA DA DÍVIDA ATIVA ESTADUAL</strong>
                </h1>
                {renderBody()}
            </div>

            <div style={{display: "flex", alignItems: "center", paddingBottom: '20px'}}>
                <div style={{flex: 20, backgroundColor: "#000444", height: "3px"}}/>
                <div style={{margin: "0 10px"}}>
                    <Checkbox id="cbxAcordo" name="cbxAcordo" value="acordo" checked={checked}
                              onChange={handleChange}/>&nbsp;&nbsp;
                    <label htmlFor="cbxAcordo"><strong>Li e estou de acordo</strong></label>
                </div>
                <div style={{flex: 1, backgroundColor: "#000444", height: "3px"}}/>
            </div>

            <div className="grid text-center">
                <div className="col-10">
                    <p>Se continuar navegando neste site, você assume concordar com nossas políticas.</p>
                    {/* <a href="http://#">Política de Privacidade e Proteção dos Dados</a> */}
                </div>
                <div className="col-2">
                    <Button label="Concordo" icon="pi pi-check" onClick={handleClick} disabled={!checked} autoFocus/>
                </div>
            </div>
        </div>
    );
}

export default Notification;