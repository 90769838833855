import React from "react";
import Loading from "./Loading";

export const RedirectLogin = ({uri}:{uri: string}) => {
    React.useEffect(() => {
        if (window.location.href.indexOf('?session_state') > 0) return;

        if (window.location.href.indexOf('openid-connect') === -1) {
            window.location.replace(uri);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Loading />
    )
}