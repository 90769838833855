export const removeMask = (value: string | undefined): string => {
    if (!value) return '';
    
    return value
        .replace(/\D/g, '')                   // substitui qualquer caracter que nao seja numero por nada
}

export const cpfMask = (value: string): string => {
    return removeMask(value)
        .replace(/(\d{3})(\d)/, '$1.$2')      // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')      // mesmo que instrução anterior
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+$/, '$1')       // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value: string): string => {
    return removeMask(value)
        .replace(/(\d{2})(\d)/, '$1.$2')      // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')      // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1/$2')      // mesmo que instrução anterior
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+$/, '$1')       // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjBaseMask = (value: string): string => {
    return removeMask(value)
        .replace(/(\d{2})(\d)/, '$1.$2')      // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')      // mesmo que instrução anterior
        .replace(/(.\d{3})\d+$/, '$1')        // captura 3 numeros seguidos de um ponto e não deixa ser digitado mais nada
}