import { useEffect, useState } from "react";
import { TUser } from "../../..";
import { AuthService } from "../../fe-api/services/AuthService";
import LogInOut from "../components/LogInOut";
import { cpfMask } from "../util/Mask";
import { TokenUtils } from "../util/TokenUtils";

const Home = () => {
  const [usuarioLogado, setUsuarioLogado] = useState<TUser>(null);
  const [hasToken, setHasToken] = useState<boolean>(false);

  const uriMtLogin = `${process.env.REACT_APP_HOST_LOGIN}/auth/realms/mt-realm/protocol/openid-connect`;
  const uriRedirect = `${process.env.REACT_APP_HOST_ASSETS}${process.env.REACT_APP_CONTEXT}`;

  const config = {
    grant_type: 'authorization_code',
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    redirect_uri: `${uriRedirect}`,

    url_token: `${uriMtLogin}/token`,
    url_userInfo: `${uriMtLogin}/userinfo`,

    url_login: `${uriMtLogin}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(uriRedirect)}&response_type=code`,
    url_logout: `${uriMtLogin}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(uriRedirect)}&response_type=code`
  }

  // Instance AuthService
  const authService = new AuthService();

  const verificaLogou = (): void => {
    if (!hasToken) {
      //verifica a url de retorno depois de logar
      const params: any = new Proxy(
        new URLSearchParams(window.location.search),
        {
          get: (searchParams: URLSearchParams, prop: string) =>
            searchParams.get(prop),
        }
      );

      if (params.code) {
        //obtem o token
        fetch(config.url_token, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: config.grant_type,
            client_id: config.client_id,
            code: params.code,
            redirect_uri: config.redirect_uri,
          }),
        })
          .then((data: Response): Promise<any> => data.json())
          .then((data: any): void => {
            if (data.access_token) {
              TokenUtils.setToken(data.access_token);
              setHasToken(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  const obtemDadosUsuario = (): void => {
    if (hasToken && !usuarioLogado) {
      fetch(config.url_userInfo, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: new URLSearchParams({
          access_token: TokenUtils.getToken()
        }),
      })
        .then((response: Response): Promise<any> => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data: any): void => {
          const user: TUser = {
            guid: data.sub,
            login: data.given_name,
            email: data.email,
            name: data.name,
            cpf: cpfMask(data.preferred_username),
            mother: data.nomeMae,
            born: data.dataNascimento
          };

          authService
            .register()
            .then(result => result)
            .catch(error => {
              console.error(error);
              return false;
            })
            .finally(() => {
              setUsuarioLogado(user);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    verificaLogou();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obtemDadosUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToken]);

  return (
    <div id="App">
      <LogInOut
        uriLogout={config.url_logout}
        uriLogin={config.url_login}
        usuarioLogado={usuarioLogado}
      />
    </div>
  );
};

export default Home;
