import React, {memo} from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Loading = () => {
    return (
        <div className="card">
            <div className="inline card-container">
                <ProgressSpinner aria-labelledby="label_status" className="absolute top-50 left-50" strokeWidth="5" />
                <div className="absolute top-50 left-50 pt-5 m-2 font-semibold">AGUARDE...</div>
            </div>
        </div>
    );
}

export default memo(Loading);