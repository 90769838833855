import React, { memo } from "react";
import { Constants } from "../util/Constants";
import { getYearCurrent } from "../util/DateUtils";
import Hyperlink from "./lib/Hyperlink";

const Footer = () => {
    const links = [
        {
            label: "Portal PGE/MT",
            href: Constants.HOME_PAGE
        },
        {
            label: "Perguntas Frequentes",
            href: Constants.COMMON_QUESTIONS
        }
    ]

    return (
        <footer className='App-footer align-items-center pb-1'>
            <div className="col-10 flex flex-column align-items-center text-center">
                <div>
                    <span>{Constants.APP_NAME}</span>
                    <span> &copy; {getYearCurrent()} {Constants.STATE_BODY}.</span>
                </div>
                <div>
                    <span>{Constants.DEVELOPED_BY}.</span>
                </div>
            </div>
            <div className="col-2 flex flex-column align-items-end">
                {links.map(link => {
                    return (
                        <div key={link.label}>
                            <Hyperlink label={link.label} href={link.href} className="App-link-footer"/>
                        </div>
                    )
                })}
            </div>
        </footer>
    );
}

export default memo(Footer);