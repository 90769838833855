import { removeMask } from "./Mask";
import { firstDigits, lastDigits } from "./StringUtils";

const allDigitsAreEqual = (value: string): boolean => value === Array(value.length).fill(value[0]).join('');

export const validateCPF = (value: string): boolean => {
    const cleanCPF = removeMask(value);
    const firstNineDigits = firstDigits(cleanCPF, 9);
    const checker = lastDigits(cleanCPF, 2);
    
    const hasCPFLength = (value: string): boolean => value.length === 11;
    
    if (!hasCPFLength(cleanCPF) || allDigitsAreEqual(cleanCPF)) {
        return false;
    }

    const calcChecker = (value: string): number => {
        let sum = 0;
    
        for (let i = 0; i < value.length; ++i) {
            sum += parseInt(value.charAt(i)) * (value.length + 1 - i);
        }
    
        const lastSumChecker = sum % 11;
        return lastSumChecker < 2 ? 0 : 11 - lastSumChecker;
    }

    const checker1 = calcChecker(firstNineDigits);
    const checker2 = calcChecker(`${firstNineDigits}${checker1}`);

    return checker === `${checker1}${checker2}`;
}

export const validateCNPJ = (value: string): boolean => {
    const cleanCNPJ = removeMask(value);
    const firstTwelveDigits = firstDigits(cleanCNPJ, 12);
    const checker = lastDigits(cleanCNPJ, 2);

    const hasCNPJLength = (value: string): boolean => value.length === 14;

    if (!hasCNPJLength(cleanCNPJ) || allDigitsAreEqual(cleanCNPJ)) {
        return false;
    }

    const calcChecker = (value: string): number => {
        let sum = 0;
    
        for (let i = 0; i < value.length; ++i) {
            sum += parseInt(value.charAt(i)) * ((value.length - 1 - i) % 8 + 2);
        }
    
        const lastSumChecker = sum % 11;
        return lastSumChecker < 2 ? 0 : 11 - lastSumChecker;
    }

    const checker1 = calcChecker(firstTwelveDigits);
    const checker2 = calcChecker(`${firstTwelveDigits}${checker1}`);

    return checker === `${checker1}${checker2}`;
}

export const validateCNPJBase = (value: string): boolean => {
    const cleanCNPJBase = removeMask(value);
    const hasCNPJBaseLength = (value: string): boolean => value.length === 8;

    return !(!hasCNPJBaseLength(cleanCNPJBase) || allDigitsAreEqual(cleanCNPJBase));
}