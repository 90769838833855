import { TokenUtils } from "../../app/util/TokenUtils";
import { Endpoints } from "../Endpoints";

export class AuthService {
  async register() {
    const response = await fetch(Endpoints.AUTHENTICATIONS, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${TokenUtils.getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return response.ok;
  }
}
