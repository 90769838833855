/* eslint-disable */
import {useRef} from "react";
import {Toast, ToastSeverityType} from "primereact/toast";

export const useToast = (initialValue: any) => {
    const toastRef = useRef<Toast>(initialValue);

    const showToast = (type: ToastSeverityType, title: string, detail: string, life?: number) => {
        toastRef.current?.show({severity: type, summary: title, detail: detail, life: life});
    }

    return [toastRef, showToast] as const;
}
/* eslint-enable */