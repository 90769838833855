import React, {memo} from "react";

const Hyperlink = memo(({label, href, className = "App-link", style}:{label: string, href: string, className?: string, style?: any}) => {
    return (
        <a href={href} target="_blank" rel="noreferrer" className={className} style={style}>
            {label}
        </a>
    );
});

export default Hyperlink;