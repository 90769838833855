import React, { useRef, useState } from "react";
import { Button } from 'primereact/button';
import { removeMask } from "../util/Mask";
import { getReference } from "../util/DateUtils";
import { firstDigits } from "../util/StringUtils";
import ShowDialog from "./lib/Dialog";
import { TUser } from "../../..";

const Navbar = ({ usuarioLogado, onLogout }: { usuarioLogado: TUser; onLogout: () => void }) => {

    const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);
    const identifier = useRef(`${firstDigits(removeMask(usuarioLogado?.cpf), 9)}-${usuarioLogado?.login}`);
    const reference = useRef(getReference());

    const handleLogout = (): void => {
        setLogoutModalOpen(true);
    }

    return (
        <>
            <header>
                <div className="card">
                    <div className="flex card-container App-header align-items-center justify-content-center">
                        <div className="col-2 align-items-center justify-content-center text-white font-bold text-center">
                            <img className="App-logo" alt="Logo da PGE/MT" src={require('../../assets/img/logo.png')} />
                        </div>
                        <div className="md:text-5xl px-7 col-8 align-items-center justify-content-center flex flex-column text-white font-bold text-center gap-2">
                            <div>Portal da Transparência da Dívida Ativa</div>
                            <div className="text-xs">Referência:&nbsp;{reference.current}</div>
                        </div>
                        <div className="col-2 flex flex-column align-items-end text-white text-center text-xs gap-2">
                            <div className="flex text-right">
                                Usuário:&nbsp;{identifier.current}
                            </div>
                            <div>
                                <Button icon="pi pi-power-off" onClick={handleLogout} tooltip="Sair" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ShowDialog
                header="Sessão Encerrada"
                visible={logoutModalOpen}
                message="Sessão encerrada com sucesso."
                onAction={onLogout}
            />
        </>
    );
}

export default Navbar;